import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogSidebar from "../components/blog-sidebar"

export default function Template({
  data: {
    allMarkdownRemark: { edges },
  },
  pageContext: {
    tag,
    totalCount
  },
}) {
  return (
    <Layout>
      <SEO title={tag} description={'Blog posts for '+tag+' on the Mindful Technologist blog by Erik Altman'} keywords={'Erik Altman, blog, article, '+tag}/>
      <div>
        <BlogSidebar />
        <div className="bg-light py-4">
          <div className="container" style={{ maxWidth: 750 }}>
            <h5 className="my-4">
              <Link to="/blog">All posts</Link> / {tag && `${totalCount} post${totalCount > 1 ? 's' : ''} with tag #${tag}`}.
            </h5>
            {
              edges
                .map((edge, i) =>
                  <div className="card my-4 shadow-sm" key={i}>
                    <Img className="card-img-top" style={{ maxWidth: 750 }} fluid={edge.node.frontmatter.featuredImage.childImageSharp.fluid} alt="Card image cap" />
                    <div className="card-body">
                      <h3 className="card-title">{edge.node.frontmatter.title}</h3>
                      <p className="card-text">{edge.node.frontmatter.description}</p>
                      <Link to={edge.node.frontmatter.path} className="btn btn-primary">Read More &rarr;</Link>
                    </div>
                  </div>
                )
            }
            <ul className="pagination justify-content-center mb-4">
              <li className="page-item disabled">
                <a className="page-link" href="/">&larr; Newer</a>
              </li>
              <li className="page-item disabled">
                <a className="page-link" href="/">Older &rarr;</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
        filter: {
          frontmatter: {
            tags: {in: [$tag]}
          }
        },
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            path
            author
            date
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
