import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Search from "./search"

export default function Template() {
  return (
    <StaticQuery
      query={staticQuery}
      render={data => (
        <div className="text-light">
          <div className="container d-flex flex-column px-5 py-5">
            <h1 className="display-4">The Mindful Technologist</h1>
            <p className="lead">A blog about mindfulness and technology.</p>
            <div className="row d-flex flex-row-reverse">
              <div className="col-12 col-md-6 col-lg-4 my-2">
                <Search  />
              </div>
              <div className="col-12 col-md-6 col-lg-8 my-2">
                {
                  data.tags.group.map((tag, i) =>
                    <Link to={`/blog/tags/${tag.tag}/`} className="d-inline-block mr-2" key={i}>
                      #{tag.tag} ({tag.totalCount})
                    </Link>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export const staticQuery = graphql`
  query {
    tags: allMarkdownRemark {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`

