import React from 'react'
import { Link } from 'gatsby'

export default function SearchHit(props) {
  return (
    <Link to={props.hit.path} className="border-light list-group-item list-group-item-action bg-light" >
        <span>
          { props.hit.title }
        </span>
        <p className="text-muted" style={{
            display: 'block',
            wordWrap: 'break-word',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            height: '3rem',
            marginBottom: 0,
        }}>
        { props.hit.excerpt }
        </p>
      </Link>
  )
}
