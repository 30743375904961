import React, { createRef, useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Stats } from 'react-instantsearch-dom';
import SearchHit from './search-hit';

export function PoweredBy() {
  return (
    <span css="font-size: 0.6em; text-align: end; padding: 0;">
      Powered by{` `}
      <a href="https://algolia.com">
        Algolia
      </a>
    </span>
  )
}

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`]
  const detectClickOutside = event =>
    ref.current && !ref.current.contains(event.target) && handler()
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside)
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  })
}

export default function Search(props) {
  const ref = createRef()
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  const getHitStyle = (show) => ({
    display: show ? 'inline' : 'none',
    position: 'absolute',
    zIndex: 2,
    marginTop: 48,
  })
  const [hitStyle, setHitStyle] = useState(getHitStyle(false));

  const onFocus = () => {
    setHitStyle(getHitStyle(true))
  }
  const onBlur = () => {
    setHitStyle(getHitStyle(false))
  }
  useClickOutside(ref, () => onBlur())

  return (
    <InstantSearch searchClient={searchClient} indexName="Portfolio">
      <div className="d-flex flex-column" ref={ref}>
        <SearchBox onFocus={() => onFocus()} />
        {/* onClick={() => onBlur()} onKeyDown={() => onBlur()} */}
        <div style={hitStyle} role="search">
          <div className="list-group shadow">
            <div className="list-group-item py-2 text-center bg-primary text-light">
              <Stats
                translations={{
                  stats(nbHits, timeSpentMS) {
                    return `${nbHits} results found in ${timeSpentMS}ms`;
                  },
                }}
              />
            </div>
            <Hits hitComponent={SearchHit}></Hits>
            <div className="list-group-item py-2 text-center bg-secondary text-light">
              <PoweredBy></PoweredBy>
            </div>
          </div>
        </div>
      </div>
    </InstantSearch>
  )
}